import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const FilterLink = ({ to, clearFilters, selectedStyles = [], children, filterData,closeMenu  }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClearFilters = () => {
    localStorage.removeItem('selectedCategories');
    localStorage.removeItem('selectedStyles');
    localStorage.removeItem('selectedColors');
    localStorage.removeItem('selectedSortOption');
  };

  const handleLinkClick = async (e) => {

    e.preventDefault();

    if (clearFilters) {
      handleClearFilters();
    }

    let filterLink = to;
    if (selectedStyles.length > 0) {
      const styleParams = selectedStyles.map(style => `${style.prosty_id}`);
      filterLink += `&styles=${styleParams.join(',')}`;
    }

    try {
      const response = await axios.get(`${API_ENDPOINTS.getFilters}`);
      const styles = response.data.styles;
      const selectedStyle = styles.find(style => style.prosty_id === selectedStyles[0].prosty_id);
      if (selectedStyle) {
        localStorage.setItem('selectedStyles', JSON.stringify([selectedStyle]));
      } else {
        console.log('No matching style found'); // Debugging
      }
    } catch (error) {
    }
    navigate(filterLink);
    if (location.pathname === '/productslist') {
      window.location.reload(); // Reload the page only if we're on the productslist page
    }
    
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <a href={to} onClick={handleLinkClick} style={{ color: 'inherit', textDecoration: 'inherit' }}>
      {children}
    </a>
  );
};

export default FilterLink;
